import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "constainer"
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("a-form", {
    attrs: {
      labelCol: {
        style: "width: 85px"
      },
      form: _vm.editorForm
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "更新端"
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["type", {
        rules: [],
        initialValue: 1
      }],
      expression: "['type', { rules: [], initialValue: 1 }]"
    }],
    attrs: {
      disabled: _vm.isDisabled,
      name: "radioGroup"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" IOS ")]), _c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_vm._v(" 安卓 ")])], 1)], 1), _c("a-form-item", {
    attrs: {
      label: "更新版本号"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["version", {
        rules: [{
          required: true,
          message: "请输入版本号!"
        }]
      }],
      expression: "[\n                        'version',\n                        {\n                            rules: [\n                                { required: true, message: '请输入版本号!' },\n                            ],\n                        },\n                    ]"
    }],
    attrs: {
      autoComplete: "off",
      disabled: _vm.isDisabled,
      size: "large",
      placeholder: "请输入",
      maxlength: "50"
    },
    on: {
      change: function change(e) {
        e.target.value = e.target.value.trim();
        return e;
      }
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "更新文案"
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["copywriting", {
        rules: [{
          required: true,
          message: "请输入更新文案!"
        }]
      }],
      expression: "[\n                        'copywriting',\n                        {\n                            rules: [\n                                { required: true, message: '请输入更新文案!' },\n                            ],\n                        },\n                    ]"
    }],
    attrs: {
      autoComplete: "off",
      disabled: _vm.isDisabled,
      size: "large",
      rows: 4,
      placeholder: "请输入"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "下载链接"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["linkurl", {
        rules: [{
          required: true,
          message: "请输入下载链接!"
        }]
      }],
      expression: "[\n                        'linkurl',\n                        {\n                            rules: [\n                                { required: true, message: '请输入下载链接!' },\n                            ],\n                        },\n                    ]"
    }],
    attrs: {
      autoComplete: "off",
      disabled: _vm.isDisabled,
      size: "large",
      placeholder: "请输入",
      maxlength: "50"
    },
    on: {
      change: function change(e) {
        e.target.value = e.target.value.trim();
        return e;
      }
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "强制更新"
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["forceupdate", {
        rules: [],
        initialValue: 0
      }],
      expression: "['forceupdate', { rules: [], initialValue: 0 }]"
    }],
    attrs: {
      disabled: _vm.isDisabled,
      name: "radioGroup"
    }
  }, [_c("a-radio", {
    staticStyle: {
      "margin-left": "-8px"
    },
    attrs: {
      value: 0
    }
  }, [_vm._v(" 否 ")]), _c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 是 ")])], 1)], 1), _c("a-form-item", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_vm.updateStatus == 0 ? _c("span", [_c("a-button", {
    staticClass: "btn btn_l",
    staticStyle: {
      background: "#F5F5F5",
      border: "0"
    },
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.editId ? "保存" : "创建"))])], 1) : _vm._e(), _vm.updateStatus == 1 ? _c("span", [_c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("确定"))])], 1) : _vm._e()])], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };