import "core-js/modules/es.object.to-string.js";
import { mapState, mapGetters, mapMutations } from "vuex";
import Mixin from '@/utils/mixin';
import { columns } from './config/index';
import { VersionApi } from '@/api';
import AddVersion from './components/AddVersion.vue';
import UpdateStatus from './components/UpdateStatus.vue';
export default {
  name: "versionManagement",
  components: {
    AddVersion: AddVersion,
    UpdateStatus: UpdateStatus
  },
  data: function data() {
    return {
      updateVisible: false,
      editId: '',
      loading: false,
      columns: columns,
      total: 0,
      page: 1,
      size: 20,
      dataSource: [],
      versionTitle: '',
      addVersionVisible: false,
      updateStatus: ''
    };
  },
  mixins: [Mixin],
  computed: {
    typeText: function typeText() {
      return function (val) {
        switch (val) {
          case 1:
            return 'IOS';
          case 2:
            return '安卓';
          default:
            return '-';
        }
      };
    },
    forceupdateText: function forceupdateText() {
      return function (val) {
        switch (val) {
          case 0:
            return '否';
          case 1:
            return '是';
          default:
            return '-';
        }
      };
    },
    statusText: function statusText() {
      return function (val) {
        switch (val) {
          case 0:
            return '生效中';
          case 1:
            return '已过期';
          default:
            return '-';
        }
      };
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    copy: function copy(e) {
      this.toast("复制成功", "success");
    },
    handleStop: function handleStop(val) {
      this.editId = val.id;
      this.updateVisible = true;
    },
    handleUpdateCancle: function handleUpdateCancle() {
      this.updateVisible = false;
    },
    handleUpdateSubmitSuccess: function handleUpdateSubmitSuccess() {
      this.getList();
      this.updateVisible = false;
    },
    handleAddCancle: function handleAddCancle() {
      this.addVersionVisible = false;
    },
    handleAddSubmitSuccess: function handleAddSubmitSuccess() {
      this.getList();
      this.addVersionVisible = false;
    },
    handleUpdateDetail: function handleUpdateDetail(val) {
      this.versionTitle = val.status == 0 ? '新建版本更新' : '查看版本更新详情';
      this.updateStatus = val.status;
      this.editId = val.id;
      this.addVersionVisible = true;
    },
    getList: function getList() {
      var _this = this;
      this.loading = true;
      var _params = {
        size: this.size,
        page: this.page
      };
      VersionApi.findpage(_params).then(function (res) {
        _this.loading = false;
        _this.dataSource = res.list;
        _this.total = res.total;
      }).catch(function () {
        _this.dataSource = [];
      }).finally(function (e) {
        setTimeout(function () {
          _this.loading = false;
        }, 3000);
      });
    },
    onPageSizeChange: function onPageSizeChange(page, size) {
      this.page = page;
      this.getList();
    },
    onShowSizeChange: function onShowSizeChange(page, size) {
      this.page = 1;
      this.size = size;
      this.getList();
    },
    handleAdd: function handleAdd() {
      this.editId = '';
      this.updateStatus = '';
      this.versionTitle = '新建版本更新';
      this.addVersionVisible = true;
    }
  }
};